@import "src/themes/mixins";
@import "src/themes/variables.module";

.teaser {
  display: flex;
  flex-direction: column;
  max-width: 1420px;
  margin: 0 auto;

  .content {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(311px, 1fr));
    gap: 20px;

    &.creatoken-card {
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }
  }
}

.page-switch-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
