.nav-buttons {
  margin-top: 20px;
  margin-right: 135px;
  height: 81px;
  position: relative;
  align-self: flex-end;

  button {
    min-width: 64px;
    min-height: 57px;
  }

  .next {
    position: absolute;
    left: 0;
  }

  .previous {
    position: absolute;
    right: -15px;
    margin-top: 27px;
  }
}
