@import "src/themes/variables.module";

.footer-wrapper {
  padding: 10px 0;
  margin-top: auto;

  .additional-info-wrapper {
    display: flex;
    margin-bottom: 20px;
  }

  .sales-future-date {
    font-size: 21px;
    line-height: 21px;
    letter-spacing: 0.32px;
    color: map-get($colors, "primary", "dark");
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;

    &.inverted {
      color: map-get($colors, "primary", "middleDark");
    }

    span {
      margin-right: 10px;
    }
  }

  .buy-wrapper {
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    padding-bottom: 10px;

    .price-wrapper {
      text-align: right;
      margin-right: 13px;
      display: flex;
      flex-direction: column;

      .primary-price {
        order: 1;
      }

      .secondary-price {
        order: 2;
      }

      .price {
        font-family: hero-new;

        &.inverted {
          color: map-get($colors, "greys", "light");
        }
      }
    }

    &.buy-wrapper-auction {
      display: flex;
      flex-direction: column;
      align-items: center;

      .price-wrapper {
        margin-right: 0;
        margin-bottom: 5px;
        text-align: center;

        .price {
          font-size: 18px;
          line-height: 17px;
          letter-spacing: 0.14px;
        }

        .ethPrice {
          font-size: 12px;
          line-height: 17px;
        }
      }

      button {
        width: 100%;
        align-self: flex-start;
      }
    }
  }

  .more-info-wrapper {
    flex: 1;
    justify-content: space-between;
    align-items: center;
    display: flex;
    text-decoration: none !important;
  }

  .turn-around-wrapper {
    position: relative;
    background-color: map-get($colors, "greys", "middleLight");
    color: white;
    clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
    width: 60px;

    .icon {
      font-size: 28px;
      position: absolute;
      bottom: 0;
      right: 6px;
    }
  }

  .info-and-buy-button-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    text-decoration: none !important;

    button {
      margin: 0;
      width: 90%;
      max-width: 250px;
    }
  }

  .details-and-auction-button-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;

    button {
      margin: 0;
      width: 80%;
      max-width: 250px;
    }
  }

  .date-wrapper {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.07px;
    color: map-get($colors, "greys", "middleDark");
  }

  &.basic-card-horizontal-small {
    border-top: 1px solid map-get($colors, "greys", "middleLight");
    height: 120px;
    padding-right: 6%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .buy-wrapper {
      padding-bottom: 0;
    }

    .sales-future-date {
      margin-bottom: 10px;
    }
  }

  &.podcast-teaser {
    border-top: 1px solid map-get($colors, "greys", "middleLight");
    height: 60px;
    padding-right: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: #{map-get($breakpoints, mobile)}) {
      margin-left: 8%;
    }
  }

  &.purchase-card-normal {
    border-top: 1px solid map-get($colors, "greys", "middleLight");
    padding: 20px 0;

    .buy-wrapper {
      padding-bottom: 0;
    }
  }
}
