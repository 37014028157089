@import "src/themes/variables.module";
@import "src/themes/mixins";

.article-page-temp-header {
    z-index: map-get($zIndices, 'basic-elements');
    width: 100%;
    position: relative;
    height: calc(91px + calc(45px * 2));

    > div:nth-of-type(2) {
        @include desktop-only {
            max-width: 1420px;
            margin: auto;
        }
        position: relative;
        height: calc(91px + calc(45px * 2));
        > div:nth-of-type(2) {
            height: 100% !important;
        }

        @media (max-width: #{map-get($breakpoints, desktop)}) {
            padding: 0 #{map-get($spacings, "medium")};
        }
    }

    nav {
        > * {
            @include mobile-only {
                display: none !important;
            }
        }
    }
}
