@import "src/themes/variables.module";

.profile-picture-wrapper {
  position: relative;
  background-color: black;
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  aspect-ratio: 128/148;
}

.profile-picture {
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  aspect-ratio: 128 / 148;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
}