@import 'src/themes/mixins';
@import 'src/themes/variables.module';

.authentification-form {
    display: flex;
    flex-direction: column;

    > * {
        margin: 10px 0;
    }

    .create-wallet-link {
        padding-top: 40px;
    }

    .wrapper {
        display: flex;

        @include mobile-and-tablet {
            flex-direction: column;
        }
    }

    .legal-wrapper {
        flex: 1;
        padding: 0 20px;

        @include mobile-and-tablet {
            padding: 100px 0 0 0;
        }
    }
}

.bottom-wrapper {
    margin-top: auto;
    display: flex;
    align-content: center;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    .dialog-image {
        height: 200px;
        @include mobile-and-tablet{
            width: 100%;
            height: 100%;
        }
    }
}

.inverted.inverted input {
    background-color: transparent;
    color: white;
}

.white-text {
    color: #fff;
}

.grey-text {
    color: map-get($colors, 'greys', 'middleLight');
}

.inverted a[class^='link-base_link-base'] span {
    color: map-get($colors, 'greys', 'middleLight');
    text-decoration: underline #{map-get($colors, 'greys', 'middleLight')};
}