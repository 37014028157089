@import 'src/themes/variables.module';
@import 'src/themes/mixins';

.support-form-wrapper {

  .form-headline {
    padding-bottom: 16px;
  }

  .contact-form {
    margin-top: 50px;

    .contact-form-input-wrapper {
      display: flex;

      > * {
        flex: 1;
      }

      .left-inputs {
        margin-right: 10px;

        .topic {
          padding-bottom: 32px;
        }
      }

      .description-area {
        margin-left: 10px;
      }
    }
  }

  .button-wrapper {
    margin-top: 48px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

@include mobile-and-tablet {
  .support-form-wrapper {

    .contact-form {
      margin-top: 50px;

      .contact-form-input-wrapper {
        display: flex;
        flex-direction: column;

        > * {
          flex: 1;
        }

        .left-inputs {
          margin-right: 0;
          margin-bottom: 32px;
        }

        .description-area {
          margin-left: 0;
          min-height: 285px;
        }
      }
    }

    .button-wrapper {
      margin-top: 48px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}