@import "src/themes/variables.module";

.instance-card-wrapper {
    position: relative;
    margin-top: 32px;
    height: 100%;
    display: flex;
    padding-right: 10%;
    flex-direction: column;

    .teaser-card-text {
        position: relative;
    }

    .optional-info {
        position: absolute;
        right: 8px;
        top: -32px;
        font-size: 14px;
        line-height: 24px;
        color: map-get($colors, "greys", "middleDark");
        letter-spacing: 0.07px;
    }

    &.basic-card-horizontal-small {
        margin-top: 0;
        padding-left: 3%;
        width: 54%;
        position: relative;
        padding-top: 35px;
        display: flex;
        height: 100%;
        flex-direction: column;
        padding-right: 0;
        .teaser-card-text {
            padding-right: 6%;
        }
        @media (max-width: #{map-get($breakpoints, mobile)}) {
            width: 100%;
            .teaser-card-text {
                padding-right: 8%;
                padding-left: 8%;
            }
        }
    }
}
