@import "src/themes/variables.module";
@import "src/themes/mixins";

.checkbox-wrapper {
  color: map-get($colors, 'greys', 'middle');

  .container {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      height: 17px;
      width: 17px;
      border: 1px solid map-get($colors, 'greys', 'dark');
      transition: background-color map-get($transitions, 'medium');

      /* Create the checkmark/indicator (hidden when not checked) */
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      /* When the checkbox is checked, add a blue background */
      &:checked ~ .checkmark {
        background-color: map-get($colors, 'greys', 'dark');
      }

      /* On mouse-over, add a grey background color */
      //&:hover:enabled ~ .checkmark {
      //  background-color: map-get($colors, 'primary', 'middleDark');
      //}
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
      left: 4px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid map-get($colors, 'primary', 'middleDark');
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.checkbox-wrapper.disabled {
  @include disabled-input();
}

.error {
  margin-top: -6px;
}