.default-header-mobile-navigation {
    width: 260px;

    > div {
        width: 260px;
    }
    position: absolute;
    right: 18.5px;
    top: 50%;
    z-index: 80;
    display: inline-block;
}
