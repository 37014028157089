@import "src/themes/variables.module";

.basic-card-wrapper {
  position: relative;

  .basic-card-content-container {
    border: 1px solid map-get($colors, "greys", "middleLight");
    color: map-get($colors, "greys", "dark");
    background-color: map-get($colors, "greys", "light");
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 10%;
    margin: 4px;

    &.selected {
      border: 5px solid map-get($colors, "primary", "middleDark");
      margin: 0;
    }

    &.inverted {
      background-color: map-get($colors, "greys", "dark");
    }

    .teaser-card-category {
      position: absolute;
      top: 0;
      right: 0;
    }

    .teaser-card-category-mobile {
      display: none;
    }

    .teaser-card-images {
      position: relative;
    }
  }

  &.basic-card-small {
    min-width: 220px;
    max-width: 340px;
    padding-top: 27px;
    .teaser-card-images {
      margin-top: -31px;
      margin-right: -1px;
    }

    .teaser-card-category {
      top: -31px;
      right: -1px;
    }

    @media (max-width: #{map-get($breakpoints, mobile)}) {
      padding-top: 30px;

      .teaser-card-images {
        margin-top: -30px;
      }
    }
  }

  &.creatoken-card {
    min-width: 160px;
    max-width: 340px;
    padding-top: 17px;
    .teaser-card-images {
      margin-top: -17px;
      margin-right: -1px;
    }

    @media (max-width: #{map-get($breakpoints, mobile)}) {
      padding-top: 24px;

      .teaser-card-images {
        margin-top: -29px;
      }
    }
  }

  &.basic-card-horizontal-small {
    padding-top: 50px;

    .basic-card-content-container {
      flex-direction: row;
      max-width: 702px;
      padding-left: 6%;

      .teaser-card-images {
        margin-top: -50px;
        width: 46%;
        z-index: map-get($zIndices, "basic-elements");
      }

      .teaser-card-category {
        position: absolute;
        top: 0;
        right: 0;
      }

      .teaser-card-text {
        padding-right: 26px;
      }

      .teaser-card-text-line {
        border-bottom: 1px solid map-get($colors, "greys", "middleLight");
      }

      .basic-card-horizontal-small-text {
        margin-top: 50px;
        margin-left: 364px;
        height: 203px;
      }

      .basic-card-horizontal-normal-text {
        margin-top: 60px;
        margin-left: 720px;
        height: 434px;
      }

      .basic-card-horizontal-small-images {
        width: 304px;
      }

      .basic-card-horizontal-normal-images {
        width: 545px;
      }
    }

    @media (max-width: #{map-get($breakpoints, mobile)}) {
      width: 100%;
      padding-top: 204px;

      .basic-card-content-container {
        flex-direction: column;
        min-width: auto;
        width: 100%;
        padding-left: 0;
        height: 376px;
      }

      .teaser-card-images {
        margin-top: -205px !important;
        margin-left: auto;
        width: 231px !important;
        margin-right: auto;
        height: 231px;
      }

      .teaser-card-category-mobile {
        position: absolute;
        top: 0;
        right: 0;
        display: inline;
      }

      .teaser-card-category {
        display: none;
      }
    }
  }

  &.podcast-teaser {
    padding-top: 50px;

    .basic-card-content-container {
      flex-direction: row;
      padding-left: 2%;
      height: 311px;

      .teaser-card-images {
        width: 300px;
        height: 300px;
        margin-top: -50px;
        margin-right: 2%;
      }

      .podcast-category-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        transform: scale(-1, 1);
      }

      .teaser-card-category {
        display: none;
      }

      .teaser-card-text {
        padding-right: 26px;
      }

      .teaser-card-text-line {
        border-bottom: 1px solid map-get($colors, "greys", "middleLight");
      }
    }

    @media (max-width: #{map-get($breakpoints, mobile)}) {
      width: 100%;
      padding-top: 30px;

      .basic-card-content-container {
        flex-direction: column;
        min-width: auto;
        width: 100%;
        padding-left: 0;
        height: 682px;
      }

      .teaser-card-images {
        width: min(405px, 100%) !important;
        height: 405px !important;
        margin-top: -30px !important;
        margin-left: auto;
        margin-right: 0 !important;
      }
    }
  }

  &.purchase-card-normal {
    min-width: 260px;
    max-width: 350px;
    padding-top: 20px;

    .basic-card-content-container {
      padding-left: 5%;
    }

    .teaser-card-images {
      width: 73%;
      margin-top: -20px;
      margin-left: auto;

      .author {
        font-size: 14px;
        line-height: 24px;
        color: #858585;
        letter-spacing: 0.07px;
      }
    }

    .teaser-card-category {
      top: -20px;
    }
  }

  &.basic-card-wrapper-normal {
    width: min(100%, 456px);
  }

  &.basic-card-horizontal-normal {
    width: min(100%, 1420px);
  }

  &.clickable {
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
    user-select: none;
  }
}
