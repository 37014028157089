@import 'src/themes/variables.module';

.category-image-wrapper {
  width: 50px;
  height: 50px;
  font-size: 24px;
  text-align: center;
  color: map-get($colors, 'greys', 'light');
}

.category-image-wrapper-art {
  background-color: map-get($colors, 'categoryColors', 'art');
}

.category-image-wrapper-audio {
  background-color: map-get($colors, 'categoryColors', 'audio');
}

.category-image-wrapper-ebook {
  background-color: map-get($colors, 'categoryColors', 'eBook');
}

.category-image-wrapper-book {
  background-color: map-get($colors, 'categoryColors', 'book');
}

.category-image-wrapper-kinked {
  clip-path: polygon(100% 0%,0% 0%,0% 70%,30% 100%,100% 100%);
  padding: 8px 0 0 3px;
}

.category-image-wrapper-arrow {
  padding: 8px 0 0 8px;
  clip-path: polygon(100% 100%, 30% 100%, 0% 50%, 30% 0%, 100% 0%);
}