@import "src/themes/variables.module";
@import "src/themes/variables.module";

.by-page-paginator {
  width: 275px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  span {
    color: map-get($colors, 'greys', 'dark');
    margin-top: 14px;
  }

  .page-item {
    cursor: pointer;
    &:hover {
      color: #{map-get($colors, "primary", "middleDark")};
    }
  }

  .current {
    display: inline-block;
    position: relative;
    color: #{map-get($colors, "primary", "middleDark")};
    font-weight: bold;
  }
  
  .current:after {
    content: '';
    position: absolute;
    width: 150%;
    height: 7px;
    bottom: 5px;
    left: -25%;    
    background-color: #{map-get($colors, "primary", "middleDark")};
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
  }

  button {
    transform: scale(0.8);
    min-height: 50px;    
    svg { 
      transform: scale(1.2);
      margin-bottom: 1px;
    }
    &:hover {
      svg {
        fill: #{map-get($colors, "primary", "middleDark")};
      }
    }
    &:disabled {
      svg {
        fill: #{map-get($colors, "greys", "middleDark")};
      }
    }
  }

  .previous {
    margin-right: 10px;
    svg {
      transform: rotate(180deg) scale(1.2);;
    }
  }

  .next {
    margin-left: 10px;
  }
}
