@import 'src/themes/variables.module';
@import 'src/themes/mixins';

.basic-card-image-wrapper {
    position: relative;


    .image {
        width: 100%;
    }

    .profile-picture-wrapper {
        position: absolute;
        width: 25%;
        left: -13px;
        bottom: -13%;

        /*Safari incorrect stacking images workaround fix*/
        -webkit-transform:translate3d(0, 0, 5px);
    }

    .podcast-duration {
        position: absolute;

        @media (max-width: 390px) {
            right: 32px;
        }
        right: 0;
        bottom: -21px;

        .duration-button {
            cursor: default;
        }
    }

    .label-wrapper {
        position: absolute;
        background-color: map-get($colors, 'categoryColors', 'art');
        left: 30px;
        bottom: -10px;
        transform: skewX(-25deg);
        padding: 4px 16px;
        color: map-get($colors, 'greys', 'light');

        .label {
            transform: skewX(25deg);
        }
    }

    &.purchase-card-normal {
        min-height: 220px;
        overflow: hidden;
    }

    &.podcast-teaser {
        .image {
            height: 300px;
            min-width: 300px;
        }

        @media (max-width: #{map-get($breakpoints, mobile)}) {
            .image {
                height: 405px;
                min-width: 405px;
            }
        }
    }
}

.basic-card-image-wrapper-widescreen {
    > img {
        object-fit: cover;
        aspect-ratio: 16 / 9;
    }
}

.basic-card-image-wrapper-fullscreen {
    > img {
        object-fit: cover;
        aspect-ratio: 4 / 3;
    }
}
